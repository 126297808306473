<script lang="ts" setup>
import { emitter } from '~/events';
import MiPixLogo from '~/assets/logo.json'

const user = useUser()

onMounted(() => document.querySelector('body')?.classList.add('launch-page'))
onUnmounted(() => document.querySelector('body')?.classList.remove('launch-page'))

const totalAmount = computed(() => user ? user.value?.wallet.balance : 0)
const amount = computed(() => user ? user.value?.wallet.balance : 0)
const amountBonus = computed(() => user ? user.value?.wallet.bonus_balance : 0)
</script>
<template>
  <div>
    <AppHeader class="hidden sm:block" />
    <div class="md:mt-10 pb-8 xl:pb-0 pt-0 md:pt-3">
      <div
        class="hidden xl:flex flex-col justify-between p-4 xl:fixed top-0 left-0 w-56 h-full z-10">
        <div class="flex flex-col">
          <nuxt-link to="/">
            <Vue3Lottie :animationData="MiPixLogo" class="!w-36" />
          </nuxt-link>
          <div class="mt-8 flex flex-col gap-1">
            <span class="font-medium text-lg text-primary-300">Saldo atual</span>
            <div class="balance-box after-gradient">
              <div class="flex gap-x-2">
                <img src="~/assets/images/icons/cash.svg" width="16" />
                <span>Principal</span>
              </div>
              <span>R${{ $numberFormat(amount, 2, ',', '.') }}</span>
            </div>
            <div class="balance-box -mt-2 after-gradient" v-if="false">
              <div class="flex items-center gap-x-2">
                <client-only>
                  <Icon name="uil:wallet" size="16" />
                </client-only>
                <span class="text-xs">Disponível</span>
              </div>
              <span class="text-xs">R${{ $numberFormat(totalAmount, 2, ',', '.') }}</span>
            </div>
            <div class="balance-box after-gradient">
              <div class="flex gap-x-2">
                <img src="~/assets/images/icons/bonus.svg" width="16" />
                <span>Bônus</span>
              </div>
              <span>R${{ $numberFormat(amountBonus, 2, ',', '.') }}</span>
            </div>
            <div class="balance-box after-gradient">
              <div class="flex gap-x-2">
                <img src="~/assets/images/icons/present.svg" width="16" />
                <span class="select-none">Rodadas Grátis</span>
              </div>
              <span class="select-none">0</span>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="w-full flex flex-col gap-2 mt-4">
            <MiPixButton outline @click="emitter.emit('cashbox-modal', { type: 'withdraw', action: 'open' })">
                {{ $t('Withdrawal').toUpperCase() }}
              </MiPixButton>
              <MiPixButton @click="emitter.emit('cashbox-modal', { type: 'deposit', action: 'open' })">
                {{ $t('Deposit').toUpperCase() }}
              </MiPixButton>
          </div>
        </div>
      </div>
      <div class="min-h-[60vh] xl:pl-52">
        <slot />
      </div>
    </div>
    <div class="hidden sm:block xl:pl-56">
      <AppFooter />
    </div>
  </div>
</template>

<style scoped>
  .balance-box {
    @apply relative py-2 flex justify-between items-center font-medium;
  }
</style>